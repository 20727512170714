<template>
  <v-form ref="form" v-model="valid" lazy-validation style="background-color: none;">
    <!-- app_name and bundle -->
    <v-card tile class="my-4 pa-2">
      <v-card-title><v-icon class="mb-1 mr-2">mdi-cog</v-icon> Configurações</v-card-title>
      <v-row class="mx-1">
        <v-col cols="8">
          <v-row>
            <v-col cols="12">
              <mf-text-input v-model="appName" :rules="[notEmpty]" :counter="30" outlined label="APP_NAME" />
            </v-col>
            <v-col class="pa-0" cols="12">
              <p class="pa-0 primary--text">
                <span class="font-weight-bold">Sugestão de Bundle ID:</span>
                <span class="pa-0 font-italic">{{ bundleIdSuggestion }}</span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-3"
                      color="primary"
                      v-bind="attrs"
                      type="button"
                      tag="a"
                      size="18"
                      style="text-decoration:none;"
                      v-on="on"
                      @click="copyText(bundleIdSuggestion)"
                      >mdi-content-copy</v-icon
                    >
                  </template>
                  <span>Copiar Bundle ID</span>
                </v-tooltip>
              </p>
            </v-col>
            <v-col cols="6">
              <mf-text-input v-model="bundleIosId" :rules="[notEmpty]" :counter="100" outlined label="IOS_BUNDLE_ID" />
            </v-col>
            <v-col cols="6">
              <mf-text-input v-model="bundleAndroidId" :rules="[notEmpty]" :counter="100" outlined label="ANDROID_BUNDLE_ID" />
            </v-col>
            <v-col>
              <mf-text-input v-model="line_business" :counter="100" outlined label="Ramo do cliente" />
            </v-col>
            <v-col>
              <mf-toggle v-model="input.debug_api" color="#334A58" label="DEBUG_API" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-row>
            <v-col>
              <v-row no-gutters class="mt-n3">
                <v-col cols="12" class="mt-3">
                  <span class="font-weight-bold">Modelo de aplicativo</span>
                </v-col>
                <v-col class="mx-3">
                  <v-checkbox v-model="input.club.active" label="Club" value />
                  <v-row no-gutters class="mt-n5 ml-5">
                    <v-col cols="12">
                      <v-checkbox v-model="input.club.web.active" label="Web" value @change="handlerCheckboxWeb('club')" />
                    </v-col>
                    <v-col cols="12" class="mt-n6">
                      <v-checkbox v-model="input.club.app.active" label="App" value @change="handlerCheckboxApp('club')" />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <v-checkbox v-model="input.ecommerce_v2.active" label="Ecommerce" value />
                  <v-row no-gutters class="mt-n5 ml-5">
                    <v-col cols="12">
                      <v-checkbox v-model="input.ecommerce_v2.web.active" label="Web" value @change="handlerCheckboxWeb('ecommerce_v2')" />
                    </v-col>
                    <v-col cols="12" class="mt-n6">
                      <v-checkbox v-model="input.ecommerce_v2.app.active" label="App" value @change="handlerCheckboxApp('ecommerce_v2')" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <!-- colors app -->
    <v-card v-if="appModel === 'spider-man'" tile class="my-4 pa-2">
      <v-card-title><v-icon class="mb-1 mr-2">mdi-brush</v-icon> Cores Aplicativo</v-card-title>
      <v-row class="mx-1">
        <v-col cols="4">
          <v-card-title><v-icon class="mb-1">mdi-cog</v-icon>BACKGROUND</v-card-title>
          <color-picker
            :rules="[notEmpty, maxLength7, input.colors.primary !== input.colors.primary_text || 'PRIMARY não pode ser igual a PRIMARY_TEXT']"
            label="PRIMARY"
            :color.sync="input.colors.primary"
            :contrast-text-color="input.colors.primary_text"
            :edit="true"
          />
          <color-picker
            :rules="[notEmpty, maxLength7, input.colors.secondary !== input.colors.secondary_text || 'SECONDARY não pode ser igual a SECONDARY_TEXT']"
            label="SECONDARY"
            :color.sync="input.colors.secondary"
            :contrast-text-color="input.colors.secondary_text"
            :edit="true"
          />
        </v-col>
        <v-col cols="4">
          <v-card-title><v-icon class="mb-1">mdi-cog</v-icon>LIGHT</v-card-title>
          <color-picker
            :rules="[
              notEmpty,
              maxLength7,
              input.colors.primary_light !== input.colors.primary_text_light || 'PRIMARY_LIGHT não pode ser igual a PRIMARY_TEXT_LIGHT'
            ]"
            label="PRIMARY_LIGHT"
            :color.sync="input.colors.primary_light"
            :contrast-text-color="input.colors.primary_text_light"
            :edit="true"
          />
          <color-picker
            :rules="[
              notEmpty,
              maxLength7,
              input.colors.secondary_light !== input.colors.secondary_text_light || 'SECONDARY_LIGHT não pode ser igual a SECONDARY_TEXT_LIGHT'
            ]"
            label="SECONDARY_LIGHT"
            :color.sync="input.colors.secondary_light"
            :contrast-text-color="input.colors.secondary_text_light"
            :edit="true"
          />
        </v-col>
        <v-col cols="4">
          <v-card-title><v-icon class="mb-1">mdi-cog</v-icon>DARK</v-card-title>
          <color-picker
            :rules="[
              notEmpty,
              maxLength7,
              input.colors.primary_dark !== input.colors.primary_text_dark || 'PRIMARY_DARK não pode ser igual a PRIMARY_TEXT_DARK'
            ]"
            label="PRIMARY_DARK"
            :color.sync="input.colors.primary_dark"
            :contrast-text-color="input.colors.primary_text_dark"
            :edit="true"
          />
          <color-picker
            :rules="[
              notEmpty,
              maxLength7,
              input.colors.secondary_dark !== input.colors.secondary_text_dark || 'SECONDARY_DARK não pode ser igual a SECONDARY_TEXT_DARK'
            ]"
            label="SECONDARY_DARK"
            :color.sync="input.colors.secondary_dark"
            :contrast-text-color="input.colors.secondary_text_dark"
            :edit="true"
          />
        </v-col>
      </v-row>
      <v-row class="mx-1">
        <v-col cols="4">
          <v-card-title><v-icon class="mb-1">mdi-cog</v-icon>TEXT</v-card-title>
          <color-picker
            :rules="[notEmpty, maxLength7, input.colors.primary !== input.colors.primary_text || 'PRIMARY não pode ser igual a PRIMARY_TEXT']"
            :edit="true"
            label="PRIMARY_TEXT"
            :color.sync="input.colors.primary_text"
          />
          <color-picker
            :rules="[notEmpty, maxLength7, input.colors.secondary !== input.colors.secondary_text || 'SECONDARY não pode ser igual a SECONDARY_TEXT']"
            :edit="true"
            label="SECONDARY_TEXT"
            :color.sync="input.colors.secondary_text"
          />
        </v-col>
        <v-col cols="4">
          <v-card-title><v-icon class="mb-1">mdi-cog</v-icon>LIGHT</v-card-title>
          <color-picker
            :rules="[
              notEmpty,
              maxLength7,
              input.colors.primary_light !== input.colors.primary_text_light || 'PRIMARY_LIGHT não pode ser igual a PRIMARY_TEXT_LIGHT'
            ]"
            :edit="true"
            label="PRIMARY_TEXT_LIGHT"
            :color.sync="input.colors.primary_text_light"
          />
          <color-picker
            :rules="[
              notEmpty,
              maxLength7,
              input.colors.secondary_light !== input.colors.secondary_text_light || 'SECONDARY_LIGHT não pode ser igual a SECONDARY_TEXT_LIGHT'
            ]"
            :edit="true"
            label="SECONDARY_TEXT_LIGHT"
            :color.sync="input.colors.secondary_text_light"
          />
        </v-col>
        <v-col cols="4">
          <v-card-title><v-icon class="mb-1">mdi-cog</v-icon>DARK</v-card-title>
          <color-picker
            :rules="[
              notEmpty,
              maxLength7,
              input.colors.primary_dark !== input.colors.primary_text_dark || 'PRIMARY_DARK não pode ser igual a PRIMARY_TEXT_DARK'
            ]"
            :edit="true"
            label="PRIMARY_TEXT_DARK"
            :color.sync="input.colors.primary_text_dark"
          />
          <color-picker
            :rules="[
              notEmpty,
              maxLength7,
              input.colors.secondary_dark !== input.colors.secondary_text_dark || 'SECONDARY_DARK não pode ser igual a SECONDARY_TEXT_DARK'
            ]"
            :edit="true"
            label="SECONDARY_TEXT_DARK"
            :color.sync="input.colors.secondary_text_dark"
          />
        </v-col>
      </v-row>
      <v-row class="mx-1">
        <v-col cols="4">
          <v-card-title><v-icon class="mb-1">mdi-cog</v-icon>SPLASH</v-card-title>
          <color-picker :rules="[notEmpty, maxLength7]" :edit="true" label="SPLASH" :color.sync="input.colors.splash" />
        </v-col>
      </v-row>

      <v-col v-if="!!input.colors.composition.general" class="pa-0">
        <v-row class="mx-1">
          <v-card-title>
            Composição geral
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" class="ml-1" v-on="on">
                  <v-icon small>info</v-icon>
                </div>
              </template>
              <span>
                Este campo reflete na identidade visual do site, mas não afeta os componentes listados abaixo(Composição por componente).
              </span>
            </v-tooltip>
          </v-card-title>
        </v-row>
        <v-row class="mx-1">
          <v-col cols="4">
            <v-select v-model="input.colors.composition.general" label="Composição geral" outlined :items="composition_color_items"></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="input.colors.composition.components.length" class="pa-0">
        <v-row class="mx-1">
          <v-card-title>
            Composição por componente
          </v-card-title>
        </v-row>
        <v-row class="mx-1">
          <v-col v-for="component in input.colors.composition.components" :key="component.name" cols="4">
            <v-select v-model="component.value" :label="component.name" outlined :items="composition_color_items"></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-card>

    <v-card v-else tile class="my-4 pa-2">
      <v-card-title><v-icon class="mb-1 mr-2">mdi-brush</v-icon> Cores Aplicativo</v-card-title>
      <v-row class="mx-1">
        <v-col cols="4">
          <color-picker
            :rules="[notEmpty, maxLength7, old_app_colors.nav !== old_app_colors.nav_text || 'NAV não pode ser igual a NAV_TEXT']"
            label="NAV"
            :color.sync="old_app_colors.nav"
            :edit="true"
          />
          <color-picker
            :rules="[notEmpty, maxLength7, old_app_colors.main !== old_app_colors.main_text || 'MAIN não pode ser igual a MAIN_TEXT']"
            label="MAIN"
            :color.sync="old_app_colors.main"
            :contrast-text-color="old_app_colors.main_text"
            :edit="true"
          />
          <color-picker
            :rules="[notEmpty, maxLength7, old_app_colors.secondary !== old_app_colors.secondary_text || 'SECONDARY não pode ser igual a SECONDARY_TEXT']"
            label="SECONDARY"
            :color.sync="old_app_colors.secondary"
            :contrast-text-color="old_app_colors.secondary_text"
            :edit="true"
          />
        </v-col>
        <v-col cols="4">
          <color-picker
            :rules="[notEmpty, maxLength7, old_app_colors.nav_text !== old_app_colors.nav || 'NAV_TEXT não pode ser igual a NAV']"
            label="NAV_TEXT"
            :color.sync="old_app_colors.nav_text"
            :edit="true"
          />
          <color-picker
            :rules="[notEmpty, maxLength7, old_app_colors.main_text !== old_app_colors.main || 'MAIN_TEXT não pode ser igual a MAIN']"
            label="MAIN_TEXT"
            :color.sync="old_app_colors.main_text"
            :edit="true"
          />
          <color-picker
            :rules="[notEmpty, maxLength7, old_app_colors.secondary_text !== old_app_colors.secondary || 'SECONDARY_TEXT não pode ser igual a SECONDARY']"
            label="SECONDARY_TEXT"
            :color.sync="old_app_colors.secondary_text"
            :edit="true"
          />
        </v-col>
        <v-col cols="4">
          <color-picker :rules="[notEmpty, maxLength7]" label="SPLASH" :color.sync="old_app_colors.splash" :edit="true" />
        </v-col>
      </v-row>
    </v-card>

    <!-- facebook credentials -->
    <v-card tile class="my-4 pa-2">
      <v-card-title><v-icon class="mb-1 mr-2">mdi-badge-account-horizontal-outline</v-icon> Credenciais</v-card-title>
      <v-row class="mx-1">
        <v-col cols="6">
          <mf-text-input v-model="display_name_temp" :rules="[notEmpty]" outlined label="DISPLAY_NAME" />
          <mf-text-input v-model="input.web_client_id" outlined label="WEB_CLIENT_ID" />
        </v-col>
        <v-col cols="6">
          <mf-text-input v-model="input.facebook.bundle" disabled outlined label="BUNDLE" />
          <mf-text-input v-model="input.codepush.api_token" disabled outlined label="API_TOKEN" />
        </v-col>
      </v-row>
    </v-card>

    <!-- ios metadata -->
    <v-card tile class="my-4 pa-2">
      <v-card-title><v-icon class="mr-2 mb-1">mdi-apple</v-icon> Metadados IOS</v-card-title>
      <v-row class="mx-1">
        <v-col cols="6">
          <mf-text-input v-model="keywordsTemp" :counter="80" :rules="[notEmpty, maxLength80]" outlined label="KEYWORDS" />
          <mf-text-input v-model="marketing_url_temp" :counter="100" :rules="[notEmpty, maxLength100]" outlined label="MARKETING_URL" />
          <mf-text-input :value="name_temp" disabled :rules="[notEmpty]" outlined label="NAME" />
          <mf-text-area-input v-model="description_temp" :counter="4000" :rules="[notEmpty, maxLength4k]" outlined label="DESCRIPTION" />
        </v-col>
        <v-col cols="6">
          <mf-text-input v-model="input.metadata.ios.privacy_url" :counter="100" :rules="[notEmpty, maxLength100]" outlined label="PRIVACY_URL" />
          <mf-text-input v-model="input.metadata.ios.release_notes" :rules="[notEmpty]" outlined label="RELEASE_NOTES" />
          <mf-text-input v-model="support_url_temp" :counter="100" :rules="[notEmpty, maxLength100]" outlined label="SUPPORT_URL" />
          <mf-text-area-input v-model="promotional_text_temp" :rules="[notEmpty]" outlined label="PROMOTIONAL_TEXT" />
        </v-col>
      </v-row>
      <v-row class="mx-1">
        <v-col cols="6">
          <mf-text-area-input v-model="short_description_temp" :counter="170" :rules="[maxLength170]" outlined label="SHORT_DESCRIPTION" />
        </v-col>
        <v-col>
          <mf-select
            v-model="type_apple_key"
            :items="[
              { value: 'mercafacil', text: 'Mercafacil' },
              { value: 'ninebits', text: '9bits' }
            ]"
            :rules="[notEmpty]"
            item-value="value"
            item-text="text"
            label="Padrão da chave que vai ser utilizada"
          >
          </mf-select>
        </v-col>
      </v-row>
    </v-card>

    <!-- android metadata -->
    <v-card tile class="my-4 pa-2">
      <v-card-title><v-icon class="mb-1 mr-2">android</v-icon> Metadados Android</v-card-title>
      <v-row class="mx-1">
        <v-col cols="6">
          <v-text-field v-model="title_temp" disabled :rules="[notEmpty]" outlined label="TITLE" />
          <mf-text-area-input v-model="full_description_temp" :counter="4000" :rules="[notEmpty, maxLength4k]" outlined label="FULL_DESCRIPTION" />
        </v-col>
        <v-col cols="6">
          <mf-text-area-input v-model="android_short_description_temp" :counter="80" :rules="[notEmpty, maxLength80]" outlined label="SHORT_DESCRIPTION" />
        </v-col>
      </v-row>
    </v-card>

    <v-card tile class="my-4 pa-2">
      <v-row justify="end">
        <v-col cols="auto"><mf-button color="error" icon="sync" label="Limpar campos" @click="cleanFields"></mf-button></v-col>
        <v-col cols="auto"><mf-button :disabled="!valid || loading" color="primary" icon="save" label="Criar app" @click="save"></mf-button></v-col>
      </v-row>
    </v-card>
  </v-form>
</template>
<script>
import { readableColor } from 'polished'
import { MUTATION_CREATE_APP } from '@/modules/accounts/graphql'
import { appDefault, appEcommerce, appPhygital, appClub } from '@/modules/accounts/assets/app/index.js'
import { debounceTime, formatClientNameForKeyword } from '@/helpers'
import { genHexLightDark } from '@/helpers/genHexLightDark'

export default {
  components: {
    ColorPicker: () => import('@/components/colors/ColorPicker.vue')
  },
  props: {
    defaultWebColors: {
      type: Object,
      default: () => ({})
    },
    defaultAppColors: {
      type: Object,
      default: () => ({})
    },
    clientName: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      app_model: '',
      name: formatClientNameForKeyword(this.clientName),
      keywordsTemp: appDefault.metadata_ios_keywords,
      app_model_items: ['octopus', 'spider-man'],
      composition_color_items: ['PRIMARY', 'SECONDARY', 'MINIMAL'],
      app_type: '',
      line_business: '',
      type_apple_key: '',
      notEmpty: v => !!v || 'Campo obrigatório',
      maxLength7: v => (v || '').length <= 7 || 'Campo não pode ter mais que 7 caractéres',
      maxLength80: v => (v || '').length <= 80 || 'Campo não pode ter mais que 80 caractéres',
      maxLength100: v => (v || '').length <= 100 || 'Campo não pode ter mais que 100 caractéres',
      maxLength170: v => (v || '').length <= 170 || 'Campo não pode ter mais que 170 caractéres',
      maxLength4k: v => (v || '').length <= 4000 || 'Campo não pode ter mais que 4000 caractéres',
      loading: false,
      valid: false,
      account_id: '',
      old_app_colors: appDefault.old_colors,
      input: {
        app_name: '',
        debug_api: false,
        store: {
          ios_bundle_id: '',
          android_bundle_id: ''
        },
        colors: {
          ...appDefault.colors,
          splash: appDefault.old_colors.splash,
          composition: {
            general: '',
            components: []
          }
        },
        web_colors: {
          ...appDefault.colors,
          composition: {
            general: '',
            components: []
          }
        },
        club: {
          active: false,
          app: {
            active: false
          },
          web: {
            active: false
          }
        },
        ecommerce_v2: {
          active: false,
          app: {
            active: false
          },
          web: {
            active: false
          }
        },
        facebook: {
          bundle: appDefault.facebook_bundle,
          display_name: ''
        },
        codepush: {
          api_token: appDefault.codepush_api_token
        },
        metadata: {
          ios: {
            keywords: '',
            marketing_url: '',
            name: '',
            description: appDefault.metadata_ios_description,
            privacy_url: appDefault.metadata_ios_privacy_url,
            release_notes: appDefault.metadata_ios_release_notes,
            support_url: '',
            promotional_text: '',
            short_description: appDefault.metadata_ios_short_description
          },
          android: {
            title: '',
            full_description: appDefault.metadata_android_full_description,
            short_description: appDefault.metadata_android_short_description
          }
        },
        web_client_id: appDefault.web_client_id
      }
    }
  },
  computed: {
    appModel() {
      return ['phygital', 'ecommerce', 'club'].includes(this.appType) ? 'spider-man' : 'octopus'
    },
    appType() {
      let res =
        this.input.club.active && this.input.ecommerce_v2.active
          ? 'phygital'
          : !this.input.club.active && this.input.ecommerce_v2.active
          ? 'ecommerce'
          : this.input.club.active && !this.input.ecommerce_v2.active
          ? 'club'
          : ''
      return res
    },
    appTypeItems() {
      if (this.app_model !== '') {
        return this.app_model === 'spider-man'
          ? [
              { value: 'club', label: 'Novo app clube' },
              { value: 'ecommerce', label: 'Novo app e-commerce' },
              { value: 'phygital', label: 'Novo app phygital' }
            ]
          : [{ value: 'default', label: 'Antigo app clube' }]
      }
      return []
    },
    appName: {
      get() {
        return this.input.app_name !== '' ? this.input.app_name : null
      },
      set(val) {
        this.input.app_name = val
          ?.normalize('NFD')
          ?.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '')
          ?.replace(/[\u0300-\u036f]/g, '')
      }
    },
    bundleIdSuggestion() {
      return this.formatBundleId(`inovatech.mercafacil.${this.input.app_name}`)
    },
    bundleIosId: {
      set(value) {
        this.input.store.ios_bundle_id = value
      },
      get() {
        return this.formatBundleId(this.input.store.ios_bundle_id)
      }
    },
    bundleAndroidId: {
      set(value) {
        this.input.store.android_bundle_id = value
      },
      get() {
        return this.formatBundleId(this.input.store.android_bundle_id)
      }
    },
    display_name_temp: {
      get() {
        return this.input.app_name || this.appName
      },
      set(value) {
        this.input.facebook.display_name = value
      }
    },
    marketing_url_temp: {
      get() {
        return this.input.metadata.ios.marketing_url || this.appName
          ? appDefault.metadata_ios_marketing_url
              ?.replace('mercafacil', this.appName)
              ?.toLowerCase()
              ?.replaceAll(' ', '')
          : appDefault.metadata_ios_marketing_url
      },
      set(value) {
        this.input.metadata.ios.marketing_url = value
      }
    },
    name_temp: {
      get() {
        return this.input.metadata.ios.name || this.appName ? this.appName : appDefault.metadata_ios_name
      }
    },
    promotional_text_temp: {
      get() {
        return this.input.metadata.ios.promotional_text || this.appName
          ? appDefault.metadata_ios_promotional_text.replaceAll('Clube Mercafacil', this.appName)
          : appDefault.metadata_ios_promotional_text
      },
      set(value) {
        this.input.metadata.ios.promotional_text = value
      }
    },
    support_url_temp: {
      get() {
        return this.input.metadata.ios.support_url || this.appName
          ? appDefault.metadata_ios_support_url.replace('mercafacil', this.appName?.toLowerCase()?.replaceAll(' ', '-'))
          : appDefault.metadata_ios_support_url
      },
      set(value) {
        this.input.metadata.ios.support_url = value
      }
    },
    description_temp: {
      get() {
        if (this.input.metadata.ios.description === appDefault.metadata_ios_description) {
          return this.appName ? this.formatString(appDefault.metadata_ios_description, 'Clube Mercafacil', this.appName) : appDefault.metadata_ios_description
        } else {
          let result = this.formatDescription(this.input.metadata.ios.description)
          return result
        }
      },
      set(value) {
        this.input.metadata.ios.description = value
      }
    },
    short_description_temp: {
      get() {
        if (this.input.metadata.ios.short_description === appDefault.metadata_ios_short_description) {
          return this.appName
            ? appDefault.metadata_ios_short_description.replaceAll('Clube Mercafacil', this.appName)
            : appDefault.metadata_ios_short_description
        } else {
          let result = this.formatDescription(this.input.metadata.ios.short_description)
          return result
        }
      },
      set(value) {
        this.input.metadata.ios.short_description = value
      }
    },
    title_temp: {
      get() {
        return this.input.metadata.android.title || this.appName ? this.appName : appDefault.metadata_android_title
      }
    },
    full_description_temp: {
      get() {
        if (this.input.metadata.android.full_description === appDefault.metadata_android_full_description) {
          return this.appName
            ? appDefault.metadata_android_full_description.replaceAll('Clube Mercafacil', this.appName)
            : appDefault.metadata_android_full_description
        } else {
          let result = this.formatDescription(this.input.metadata.android.full_description)
          return result
        }
      },
      set(value) {
        this.input.metadata.android.full_description = value
      }
    },
    android_short_description_temp: {
      get() {
        if (this.input.metadata.android.short_description === appDefault.metadata_android_short_description) {
          return this.appName
            ? appDefault.metadata_android_short_description.replaceAll('Clube Mercafacil', this.appName)
            : appDefault.metadata_android_short_description
        } else {
          let result = this.formatDescription(this.input.metadata.android.short_description)
          return result
        }
      },
      set(value) {
        this.input.metadata.android.short_description = value
      }
    }
  },
  watch: {
    app_model(model, oldModel) {
      this.app_type = ''
    },
    appType() {
      let keyword = appDefault.metadata_ios_keywords.replaceAll('Mercafacil', this.name).concat(
        ',' +
          this.name
            ?.split(' ')
            .map(s => s)
            .toString()
      )
      switch (this.appType) {
        case 'ecommerce':
          this.keywordsTemp = keyword
          this.description_temp = appEcommerce.metadata_ios_description
          this.short_description_temp = appEcommerce.metadata_ios_short_description
          this.full_description_temp = appEcommerce.metadata_android_full_description
          this.android_short_description_temp = appEcommerce.metadata_android_short_description
          break
        case 'phygital':
          this.keywordsTemp = keyword
          this.description_temp = appPhygital.metadata_ios_description
          this.short_description_temp = appPhygital.metadata_ios_short_description
          this.full_description_temp = appPhygital.metadata_android_full_description
          this.android_short_description_temp = appPhygital.metadata_android_short_description
          break
        case 'club':
          keyword += `, clube ${this.name} clube`
          this.keywordsTemp = keyword
          this.description_temp = appClub.metadata_ios_description
          this.short_description_temp = appClub.metadata_ios_short_description
          this.full_description_temp = appClub.metadata_android_full_description
          this.android_short_description_temp = appClub.metadata_android_short_description
          break
        default:
          this.keywordsTemp = keyword
          this.description_temp = appDefault.metadata_ios_description
          this.short_description_temp = appDefault.metadata_ios_short_description
          this.full_description_temp = appDefault.metadata_android_full_description
          this.android_short_description_temp = appDefault.metadata_android_short_description
          break
      }
    },
    'old_app_colors.main': function(color, oldColor) {
      this.onAfterColorChange(color, oldColor, () => {
        this.old_app_colors.main_text = this.customReadableColor(color)
      })
    },
    'old_app_colors.secondary': function(color, oldColor) {
      this.onAfterColorChange(color, oldColor, () => {
        this.old_app_colors.secondary_text = this.customReadableColor(color)
      })
    },
    'input.colors.primary': function(color, oldColor) {
      this.onAfterColorChange(color, oldColor, () => {
        const [baseLight, baseDark] = genHexLightDark(color)

        this.input.colors = {
          ...this.input.colors,
          primary_light: baseLight,
          primary_dark: baseDark,
          primary_text: this.customReadableColor(color),
          primary_text_light: this.customReadableColor(baseLight),
          primary_text_dark: this.customReadableColor(baseDark)
        }
      })
    },
    'input.colors.primary_light': function(color, oldColor) {
      this.onAfterColorChange(color, oldColor, () => {
        this.input.colors.primary_text_light = this.customReadableColor(color)
      })
    },
    'input.colors.primary_dark': function(color, oldColor) {
      this.onAfterColorChange(color, oldColor, () => {
        this.input.colors.primary_text_dark = this.customReadableColor(color)
      })
    },
    'input.colors.secondary': function(color, oldColor) {
      this.onAfterColorChange(color, oldColor, () => {
        const [baseLight, baseDark] = genHexLightDark(color)

        this.input.colors = {
          ...this.input.colors,
          secondary_light: baseLight,
          secondary_dark: baseDark,
          secondary_text: this.customReadableColor(color),
          secondary_text_light: this.customReadableColor(baseLight),
          secondary_text_dark: this.customReadableColor(baseDark)
        }
      })
    },
    'input.colors.secondary_light': function(color, oldColor) {
      this.onAfterColorChange(color, oldColor, () => {
        this.input.colors.secondary_text_light = this.customReadableColor(color)
      })
    },
    'input.colors.secondary_dark': function(color, oldColor) {
      this.onAfterColorChange(color, oldColor, () => {
        this.input.colors.secondary_text_dark = this.customReadableColor(color)
      })
    }
  },
  mounted() {
    this.account_id = this.$route.params.id
    this.keywordsTemp = this.keywordsTemp.replaceAll('Mercafacil', this.name).concat(
      ',' +
        this.name
          ?.split(' ')
          .map(s => s)
          .toString()
    )
    this.setDefaultComposition({ composition: this.defaultAppColors?.composition ?? null })
    this.setDefaultComposition({ composition: this.defaultWebColors?.composition ?? null, type: 'web' })
  },
  methods: {
    handlerCheckboxApp(type) {
      if (this.input[type].app.active) this.input[type].active = true
    },
    handlerCheckboxWeb(type) {
      if (this.input[type].web.active) this.input[type].active = true
    },
    onAfterColorChange(color, oldColor, cb) {
      if (color && oldColor && color !== oldColor) {
        debounceTime(() => {
          return cb()
        }, 400)
      }
    },
    customReadableColor(color) {
      return readableColor(color, '#000000', '#FFFFFF')
    },
    setDefaultComposition({ composition, type = 'app' }) {
      if (!composition) return

      const parsedComponents = composition.components.map(({ __typename, ...rest }) => rest)

      const input_key = type === 'web' ? 'web_colors' : 'colors'

      this.input[input_key] = {
        ...this.input[input_key],
        composition: {
          general: composition.general ?? '',
          components: parsedComponents
        }
      }
    },
    formatString(string_to_format, value_to_replace, new_value) {
      return string_to_format.replaceAll(value_to_replace, new_value)
    },
    formatDescription(value) {
      let result = value
      result = this.appName ? this.formatString(result, 'Clube Mercafacil', this.appName) : result
      result = this.marketing_url_temp !== '' ? this.formatString(result, 'https://www.mercafacil.com/', this.marketing_url_temp) : result
      result = this.line_business !== '' ? this.formatString(result, 'SUPERMERCADO', this.line_business) : result
      return result
    },
    formatBundleId(bundleId) {
      const bundle = bundleId
        ?.toLowerCase()
        ?.trim()
        ?.replaceAll(' do ', '.')
        ?.replaceAll('super', 'sup')
        ?.replaceAll('nano', '')
        ?.replaceAll(' ', '.')
        ?.replaceAll('..', '.')
        ?.replace(/\d+/g, '')
      return bundle
        ?.normalize('NFD')
        ?.replace(/[\u0300-\u036f]/g, '')
        ?.replace(/\.{2,}/gm, '.')
        ?.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '')
    },
    copyText(bundle_id) {
      try {
        navigator.clipboard.writeText(bundle_id)
        this.$alert({
          alert_message: 'Bundle ID copiado',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
      } catch (err) {
        console.log(err.message)
        this.$alert({
          alert_message: 'Falha ao copiar',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    async save() {
      if (!this.$refs.form.validate())
        return this.$alert({
          alert_message: 'Falha ao validar campos',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      this.loading = true

      const variables = {
        account_id: this.account_id,
        appleKeyType: this.type_apple_key,
        input: {
          ...this.input,
          app_type: this.appType,
          debug_api: this.input.debug_api ? '1' : '0',
          store: {
            android_bundle_id: this.bundleAndroidId,
            ios_bundle_id: this.bundleIosId
          },
          facebook: {
            ...this.input.facebook,
            display_name: this.display_name_temp
          },
          metadata: {
            android: {
              full_description: this.full_description_temp,
              short_description: this.android_short_description_temp,
              title: this.title_temp
            },
            ios: {
              ...this.input.metadata.ios,
              keywords: this.keywordsTemp,
              marketing_url: this.marketing_url_temp,
              name: this.name_temp,
              description: this.description_temp,
              support_url: this.support_url_temp,
              promotional_text: this.promotional_text_temp,
              short_description: this.short_description_temp
            }
          },
          club: this.input.club,
          ecommerce_v2: this.input.ecommerce_v2,
          colors:
            this.appModel === 'spider-man'
              ? { main: this.input.colors.primary, main_text: this.input.colors.primary_text, ...this.input.colors }
              : this.old_app_colors
        }
      }

      try {
        const res = await this.$apollo.mutate({
          mutation: MUTATION_CREATE_APP,
          variables,
          context: {
            headers: {
              authorization: localStorage.getItem('session_id')
            },
            uri: this.$microservicesUrls['app_config']
          }
        })
        const createdApp = res?.data?.createApp?.app_name
        this.$alert({
          alert_message: `App "${createdApp}" criado com sucesso`,
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.$router.go()
      } catch (err) {
        console.log(err?.message)
        this.$alert({
          alert_message: 'Falha ao criar app',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
      this.loading = false
    },
    cleanFields() {
      this.input.app_name = ''
      this.app_model = ''
      this.input.debug_api = false
      this.input.store.ios_bundle_id = ''
      this.input.store.android_bundle_id = ''
      this.old_app_colors = this.appDefault.old_colors
      this.input.colors = {
        ...appDefault.colors,
        splash: appDefault.old_colors.splash,
        composition: {
          general: '',
          components: []
        }
      }
      this.input.web_colors = {
        ...appDefault.colors,
        composition: {
          general: '',
          components: []
        }
      }
      this.input.facebook.bundle = 'fb326410019008941'
      this.input.facebook.display_name = ''
      this.input.codepush.api_token = '3f4ea6d8246359fdcbd2ddd9c0c463040237713e'
      this.input.web_client_id = '939456025008-kq6c8v018mojjh6c2p5a27ksmi7jaqim'
      this.input.metadata.ios.keywords = ''
      this.input.metadata.ios.marketing_url = ''
      this.input.metadata.ios.name = ''
      this.input.metadata.ios.description = ''
      this.input.metadata.ios.privacy_url = 'https://regulamentos.mercafacil.com/mercafacil_politica_privacidade.html'
      this.input.metadata.ios.release_notes =
        'Refizemos nosso login e cadastro e alguns ajustes no visual das ofertas para melhorar a experiencia dos usuários.'
      this.input.metadata.ios.support_url = ''
      this.input.metadata.ios.promotional_text = ''
      this.input.metadata.ios.short_description = ''
      this.input.metadata.android.title = ''
      this.input.metadata.android.full_description = ''
      this.input.metadata.android.short_description = ''
      this.$alert({
        alert_message: 'Campos reiniciados',
        alert_title: 'Sucesso!',
        alert_color: 'success',
        alert_icon: 'mdi-check-circle'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.mf-text-input {
  padding: 5px 15px;
}
span {
  padding: 5px 15px;
}
</style>
